import { http } from '../../http';

export default {
  getAudioLectures(params = {}, filter = {}) {
    return http.get('/audiolectures', {
      params: {
        per_page: params.per_page || 8,
        page: params.page || 1,
        sort_by: filter.sort_by,
        category: filter.category,
        start_century: filter.startCentury,
        end_century: filter.endCentury,
        with_external: 1,
      },
    });
  },

  getAudioLectureBySlug(slug) {
    return http.get(`/audiolectures/${slug}`);
  },

  getRandomAudioLecture(count = 9, excludeIds = []) {
    return http.get('/random/audiolectures', {
      params: {
        rand: count,
        exclude_ids: excludeIds?.join(','),
      },
    });
  },

  getPodcasts() {
    return http.get('/podcasts');
  },

  getVideoMaterials(params = {}) {
    return http.get('/videomaterials', {
      params: {
        per_page: params.per_page || 8,
        page: params.page || 1,
        with_external: 1,
      },
    });
  },

  getVideoLectures(params = {}, filter = {}) {
    return http.get('/videomaterials?type=lecture', {
      params: {
        per_page: params.per_page || 8,
        page: params.page || 1,
        sort_by: filter.sort_by,
        category: filter.category,
        start_century: filter.startCentury,
        end_century: filter.endCentury,
        with_external: 1,
      },
    });
  },

  getVideoLectureBySlug(slug) {
    return http.get(`/videomaterials/${slug}`);
  },

  getRandomVideoMaterials(count = 5) {
    return http.get('/random/videomaterials', {
      params: {
        rand: count,
      },
    });
  },

  getRandomVideoLecture(count = 9) {
    return http.get('/random/videolectures', {
      params: {
        rand: count,
      },
    });
  },

  getFilms(params = {}, filter = {}) {
    return http.get('/videomaterials?type=film', {
      params: {
        per_page: params.per_page || 16,
        page: params.page || 1,
        sort_by: filter.sort_by,
        category: filter.category,
        start_century: filter.startCentury,
        end_century: filter.endCentury,
        with_external: 1,
      },
    });
  },

  getAudioLecturesCategories(params = {}) {
    return http.get('/categories/audio', {
      params: {
        start_century: params.startCentury,
        end_century: params.endCentury,
      },
    });
  },

  getVideoLecturesCategories(params = {}) {
    return http.get('/categories/video', {
      params: {
        start_century: params.startCentury,
        end_century: params.endCentury,
      },
    });
  },
};
