import articles from '@/services/api/modules/articles';
import auth from '@/services/api/modules/auth';
import authors from '@/services/api/modules/authors';
import biographies from '@/services/api/modules/biographies';
import collections from '@/services/api/modules/collections';
import comments from '@/services/api/modules/comments';
import courses from '@/services/api/modules/courses';
import documents from '@/services/api/modules/documents';
import dostoevsky from '@/services/api/modules/dostoevsky';
import events from '@/services/api/modules/events';
import games from '@/services/api/modules/games';
import gpt from '@/services/api/modules/gpt';
import lectures from '@/services/api/modules/lectures';
import magazines from '@/services/api/modules/magazines';
import news from '@/services/api/modules/news';
import others from '@/services/api/modules/others';
import pinned from '@/services/api/modules/pinned';
import regions from '@/services/api/modules/regions';
import socials from '@/services/api/modules/socials';
import subscription from '@/services/api/modules/subscription';
import surveys from '@/services/api/modules/surveys';
import teachers from '@/services/api/modules/teachers';
import tests from '@/services/api/modules/tests';
import timeline from '@/services/api/modules/timeline';
import user from '@/services/api/modules/user';

export default {
  dostoevsky,
  comments,
  articles,
  news,
  courses,
  collections,
  events,
  authors,
  biographies,
  lectures,
  auth,
  socials,
  tests,
  documents,
  user,
  pinned,
  timeline,
  others,
  magazines,
  subscription,
  teachers,
  surveys,
  games,
  gpt,
  regions,
};
